<template>
  <el-dialog top="3vh" :visible.sync="showDialog" width="760px">
    <div slot="title" style="text-align: center" class="slot-title">
      <span style="background: #eceafa; padding: 5px">
        {{ this.detail ? "修改评论" : "新建评论" }}</span
      >
    </div>
    <div class="dialog">
      <div class="flex-row flex-ac">
        <div class="title">
          <span style="color: red; font-size: 16px">*</span>用户名称
        </div>
        <el-input
          v-model="form.commentUserName"
          style="width: 200px"
          size="mini"
          placeholder="请输入"
        ></el-input>
      </div>
      <div class="flex-row flex-as">
        <div class="title">
          <span style="color: red; font-size: 16px">*</span>头像
        </div>
        <el-upload
          class="avatar-uploader"
          :action="$config.VUE_APP_URL + $filesUrl[0]"
          :show-file-list="false"
          :on-success="uploadSuccess.bind(this)"
          :headers="{
            Authorization: `bearer ${accessToken}`,
          }"
          :data="{
            fileType: 1,
          }"
          :before-upload="beforeUpload"
        >
          <img
            v-if="form.userHeadPicturePath"
            :src="form.userHeadPicturePath"
            class="avatar"
          />
          <i v-else class="el-icon-plus avatar-uploader-icon"></i>
        </el-upload>
        <!-- <div class="avator flex-cloumn flex-ac">
          <div class="avator-block"></div>
          <el-button
            :loading="loadingArr[1]"
            size="mini"
            type="primary"
            @click="checkZeroPrice(1)"
            >选择头像</el-button
          >
        </div> -->
      </div>
      <div class="flex-row flex-ac">
        <div class="title">
          <span style="color: red; font-size: 16px">*</span>剧本名称
        </div>
        <el-select
          size="mini"
          placeholder="请选择已上架剧本"
          v-model="form.scenarioId"
        >
          <el-option
            v-for="(option, index) in scripts"
            :key="index"
            :label="option.scenarioName"
            :value="option.id"
          ></el-option>
        </el-select>
      </div>
      <!-- <div v-if="!detail" class="flex-row flex-ac">
        <div class="title">
          <span style="color: red; font-size: 16px">*</span>状态
        </div>
        <el-button-group style="overflow: hidden; border-radius: 10px">
          <el-button
            round
            @click="form.isTransparency = false"
            :type="!form.isTransparency ? 'primary' : ''"
            size="mini"
            >正常</el-button
          >
          <el-button
            round
            @click="form.isTransparency = true"
            :type="form.isTransparency ? 'primary' : ''"
            size="mini"
            >剧透</el-button
          >
        </el-button-group>
      </div> -->
      <div class="flex-row flex-ac">
        <div class="title">
          <span style="color: red; font-size: 16px">*</span>评分
        </div>
        <span>剧本评分：</span>
        <el-input
          v-model="form.scenarioCommentScore"
          oninput="if(value>10)value=10;if(value<1)value=1"
          style="width: 65px; margin-right: 20px"
          size="mini"
          type="number"
        ></el-input>

        <span>游戏体验：</span>
        <el-input
          v-model="form.gameExperienceScore"
          oninput="if(value>10)value=10;if(value<1)value=1"
          style="width: 65px; margin-right: 20px"
          size="mini"
          type="number"
        ></el-input>
      </div>
      <div class="flex-row flex-as p-r">
        <div class="title">
          <span style="color: red; font-size: 16px">*</span>评论内容
        </div>
        <el-input
          v-model="form.commentContent"
          style="width: 430px"
          size="mini"
          type="textarea"
          placeholder="请输入评论内容"
        ></el-input>
      </div>
    </div>
    <span slot="footer" class="dialog-footer" v-if="form.saleStatus !== 1">
      <!-- <el-button v-if="id" size="mini" plain type="danger" @click="deleteScript"
        >删除</el-button
      > -->
      <el-button
        :loading="loading"
        size="mini"
        type="primary"
        @click="addComment"
        >保存</el-button
      >
    </span>
  </el-dialog>
</template>

<script>
// import { deepCopy } from "@tools";
import { formRule } from "@tools";
export default {
  props: {
    detail: {
      default: null,
    },
    scripts: {
      default: () => [],
    },
    scenarioId: {
      default: null,
    },
  },
  data() {
    this.formRule = [
      {
        label: "commentUserName",
        name: "用户名称",
        rule: {
          required: true,
        },
      },
      {
        label: "userHeadPicturePath",
        name: "头像",
        rule: {
          required: true,
        },
      },
      {
        label: "scenarioId",
        name: "剧本名称",
        rule: {
          required: true,
        },
      },
      {
        label: "scenarioCommentScore",
        name: "剧本评分",
        rule: {
          required: true,
        },
      },
      {
        label: "gameExperienceScore",
        name: "游戏体验评分",
        rule: {
          required: true,
        },
      },
      {
        label: "commentContent",
        name: "评论内容",
        rule: {
          required: true,
        },
      },
    ];
    return {
      showDialog: false,
      loading: false,
      form: {
        commentContent: "",
        commentUserName: "",
        gameExperienceScore: "",
        isTransparency: false,
        scenarioCommentScore: "",
        userHeadPicturePath: "",
        scenarioId: "",
      },
      accessToken: localStorage.getItem("access_token"),
    };
  },
  created() {},
  methods: {
    async addComment() {
      if (!formRule(this.form, this.formRule)) return;
      if (this.detail) {
        if (this.form.scenarioCommentScore < 6) {
          this.form.isTransparency = true;
        }
      }
      this.loading = true;
      await this.$api.message[this.detail ? "editComment" : "addComment"](
        this.form
      ).finally(() => {
        this.loading = false;
      });
      this.showDialog = false;
      this.$message.success(this.detail ? "修改成功！" : "创建成功！");
      // }
      this.$emit("getCommentList");
    },
    beforeUpload(file) {
      const isPNG = file.type === "image/png";
      const isJPG = file.type === "image/jpeg";
      const isGIF = file.type === "image/gif";
      const isLt2M = file.size / 1024 / 1024 < 2;

      if (!(isPNG || isJPG || isGIF)) {
        this.$message.error("上传图片只能是 PNG或JPG, GIF格式!");
      }
      if (!isLt2M) {
        this.$message.error("上传图片大小不能超过 2MB!");
      }
      return (isPNG || isJPG || isGIF) && isLt2M;
    },
    uploadSuccess(res) {
      this.form.userHeadPicturePath = res.url;
    },
  },
  computed: {},
  watch: {
    showDialog(newVal) {
      if (newVal) {
        Object.assign(
          this.form,
          this.detail ? this.detail : this.$options.data().form
        );
        if (!this.detail) {
          this.$delete(this.form, "id");
          this.form.scenarioId = this.scenarioId;
        }
      }
    },
  },
};
</script>

<style scoped lang="scss">
@import "@style/form.scss";
</style>