<template>
  <el-dialog
    :visible.sync="showDialog"
    width="760px"
    @close="this.showDialog = false"
  >
    <div slot="title" style="text-align: center" class="slot-title">
      <span style="background: #eceafa; padding: 5px"> 填充评论 </span>
    </div>
    <div class="tab">
      <!-- tab栏 -->
      <div
        style="margin-right: 10px"
        class="tab-item"
        v-for="item in tabMenu"
        :key="item.id"
        :class="curTab === item.id ? 'tab-item-active' : ''"
        @click="curTab = item.id"
      >
        {{ item.name }}
      </div>
    </div>
    <div class="m-t-10 m-b-30 random-list" v-loading="loading">
      <div class="remark-text m-b-10">
        注：以下勾选并保存的评论，会作为用户游戏后“剧终评价”的随机填充评论。
      </div>
      <div
        v-for="(item, index) in randomComments"
        :key="index"
        class="flex-row flex-ac m-b-10"
      >
        <el-checkbox v-model="item.status"></el-checkbox>
        <!-- 商品序号 -->
        <div class="item-index">
          {{ index + 1 }}
        </div>
        <el-input
          class="m-l-20"
          size="mini"
          :value="item.content"
          v-model="item.content"
        />
      </div>
    </div>
    <div class="random-center flex-cloumn flex-ac">
      <el-button size="mini" @click="addRandom">添加</el-button>
      <el-pagination
        @current-change="handleCurrentChange"
        :current-page.sync="params.page"
        :page-size="10"
        :total="count"
        layout="prev, pager, next"
      ></el-pagination>
    </div>
    <span slot="footer" class="dialog-footer">
      <el-button type="primary" size="mini" @click="submit" :loading="loading"
        >保存</el-button
      >
    </span>
  </el-dialog>
</template>

<script>
export default {
  props: {
    scenarioId: {
      type: String,
    },
  },
  data() {
    return {
      loading: false,
      showDialog: false,
      randomComments: [],
      count: 0,
      params: {
        limit: 10,
        page: 1,
        commentType: 0,
      },
      tabMenu: [
        { name: "普通评论", id: 0 },
        { name: "低分评论", id: 1 },
      ],
      curTab: 0,
    };
  },
  created() {
    this.getRandomComment();
  },
  methods: {
    async getRandomComment() {
      this.loading = true;
      const { count, data } = await this.$api.message
        .getRandomComment({ ...this.params, commentType: this.curTab })
        .finally(() => {
          this.loading = false;
        });
      this.randomComments = data;
      this.count = count;
      this.changeBoolean(0);
    },
    handleCurrentChange(page) {
      this.params.page = page;
      this.getRandomComment();
    },
    addRandom() {
      this.randomComments.push({
        id: "",
        scenarioId: this.scenarioId,
        status: false,
        content: "",
      });
    },
    changeBoolean(type) {
      this.randomComments.forEach(({ status }, index) => {
        if (type) {
          this.randomComments[index].status = status ? 1 : 0;
        } else {
          this.randomComments[index].status = status ? true : false;
        }
      });
    },
    async submit() {
      if (this.randomComments.some(({ content }) => content.trim() === "")) {
        this.$message.warning("请输入内容");
        return;
      }
      this.changeBoolean(1);
      this.loading = true;
      this.randomComments.forEach((_, index) => {
        this.randomComments[index].commentType = this.curTab
      })
      await this.$api.message
        .editRandomComment(this.randomComments)
        .finally(() => {
          this.loading = false;
        });
      this.getRandomComment();
    },
  },
  watch: {
    showDialog(newVal) {
      if (!newVal) {
        this.randomComments = this.randomComments.filter(({ id }) => id);
      }
    },
    curTab() {
      this.getRandomComment();
    },
  },
};
</script>

<style lang="scss" scoped>
@import '../style/shop.scss';
.random-center {
  position: absolute;
  left: 50%;
  transform: translateX(-50%);
}
.random-list {
  max-height: 400px;
  overflow-y: scroll;
}
.item-index {
  margin-left: 10px;
  min-width: 18px;
  height: 18px;
  border-radius: 4px;
  border: 2px solid #c5c0e0;
  color: #c5c0e0;
  font-size: 12px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  font-family: SourceHanSansCN-Regular, SourceHanSansCN;
}
</style>