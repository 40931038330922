<template>
  <div class="h-100">
    <div class="flex-cloumn h-100">
      <!-- 筛选表单 -->
      <div class="btn-wrap flex-row space-between">
        <div
          class="creatNewBtn"
          v-hasAuth="'scenarioComment-add'"
          @click="openDialog('addComment')"
        >
          新建评论
        </div>
        <el-button
          size="mini"
          class="add-random"
          @click="openDialog('addRandom')"
        >
          填充评论设置
        </el-button>
      </div>
      <div class="select-wrap">
        <div style="display: flex; align-items: center">
          <div>剧本名称：</div>
          <el-select
            filterable
            v-model="tableFilter1.scenarioId"
            style="width: 200px"
            class="select-item"
            size="mini"
            @change="getCommentList(true)"
            placeholder="剧本名称"
          >
            <el-option
              v-for="option in scripts"
              :key="option.id"
              :label="option.scenarioName"
              :value="option.id"
            ></el-option>
          </el-select>
        </div>
        <!-- <el-input
          style="width: 226px"
          placeholder="搜索关键字"
          size="mini"
        ></el-input> -->
      </div>
      <div class="row2 flex-1" style="height:calc(100% - 158px);">
        <el-card class="row2-card" v-loading="loading">
          <!-- 表头 -->
          <div class="flex-row table-header" style="padding-left: 60px">
            <div style="width: 156px; text-align: left">用户名称</div>
            <div style="width: 260px">评论摘要</div>
            <div style="width: 40px">评分</div>
            <div style="width: 220px">排序</div>
            <div style="width: 70px">状态</div>
            <div style="width: 50px">操作</div>
            <div
              style="width: 70px; padding-left: 10px"
              class="relative-left-10"
            >
              创建日期
            </div>
          </div>
          <!-- 行数据 -->
          <div
            class="item"
            @click="openDialog('addComment', item)"
            v-for="(item, i) in list"
            :key="i"
            :style="{
              background: i % 2 !== 0 ? '#fff' : 'rgba(250,249,255,1)',
            }"
          >
            <!-- <el-checkbox></el-checkbox> -->
            <!-- 商品序号 -->
            <div class="item-index">
              {{ i + 1 }}
            </div>
            <div class="flex-row space-around flex-1">
              <!-- 名称 -->
              <div class="item-title shenglue-1" style="width: 130px">
                {{ item.commentUserName }}
              </div>

              <el-popover
                placement="right-end"
                title=""
                width="200"
                trigger="hover"
                :open-delay="500"
                :content="item.commentContent"
              >
                <div
                  slot="reference"
                  class="item-message shenglue-1"
                  style="width: 260px"
                >
                  {{ item.commentContent }}
                </div>
              </el-popover>
              <!-- <div class="item-message shenglue-1" style="width: 260px">
                {{ item.commentContent }}
              </div> -->
              <div
                class="item-message shenglue-1"
                style="width: 40px; text-align: center"
              >
                {{ item.avgScore }}
              </div>
              <div class="item-position shenglue-1" style="width: 220px">
                <div
                  v-hasAuth="'scenarioComment-top'"
                  :class="[
                    'position-btn',
                    'm-r-10',
                    item.topFlag ? 'gray' : '',
                  ]"
                  @click.stop="topComment(item.id, item.topFlag)"
                >
                  置顶
                </div>
                <div
                  v-hasAuth="'scenarioComment-move'"
                  :class="[
                    'position-btn',
                    i === 0 && tableFilter1.currPage === 1 ? 'gray' : '',
                  ]"
                  @click.stop="
                    moveComment(
                      item.id,
                      0,
                      i === 0 && tableFilter1.currPage === 1
                    )
                  "
                >
                  上移
                </div>
                <div
                  v-hasAuth="'scenarioComment-move'"
                  :class="[
                    'position-btn',
                    i === list.length - 1 && isLast ? 'gray' : '',
                  ]"
                  @click.stop="
                    moveComment(item.id, 1, i === list.length - 1 && isLast)
                  "
                >
                  下移
                </div>
              </div>
              <div
                v-hasAuth="'scenarioComment-spoiler'"
                @click.stop="changeTransparency(item.id, !item.isTransparency)"
                class="item-position shenglue-1"
                style="width: 70px"
              >
                <div
                  :class="[
                    'position-btn',
                    'status-left',
                    item.isTransparency ? '' : 'bg',
                  ]"
                >
                  正常
                </div>
                <div
                  :class="[
                    'position-btn',
                    'status-right',
                    item.isTransparency ? 'bg' : '',
                  ]"
                >
                  剧透
                </div>
              </div>
              <div
                @click.stop="deleteComment(item.id)"
                class="item-position"
                style="width: 50px"
              >
                <div
                  class="position-btn delete-btn"
                  v-hasAuth="'scenarioComment-remove'"
                >
                  删除
                </div>
              </div>
              <div class="item-date shenglue-1" style="width: 80px">
                <div class="state">
                  {{ item.createTime }}
                </div>
              </div>
            </div>
          </div>
        </el-card>
      </div>
    </div>
    <div class="row3">
      <el-pagination
        :total="total"
        :current-page.sync="tableFilter1.currPage"
        :page-size.sync="tableFilter1.limit"
        @current-change="getCommentList()"
        layout="prev, pager, next"
      ></el-pagination>
    </div>
    <add-comment
      :scenarioId="tableFilter1.scenarioId"
      @getCommentList="getCommentList"
      :detail="commentDetail"
      ref="addComment"
      :scripts="scripts"
    />
    <addRadom ref="addRandom" :scenarioId="tableFilter1.scenarioId" />
  </div>
</template>
<script>
import addComment from "./common/component/addComment";
import addRadom from './common/component/addRandom'
import { mapState } from "vuex";
export default {
  components: {
    addComment,
    addRadom
  },
  data() {
    return {
      loading: false,
      // tab栏
      curTab: 1,
      isNew: false,
      scripts: [],
      // 搜索
      tableFilter1: {
        currPage: 1,
        limit: 35,
        scenarioId: "",
      },
      list: [],
      total: 100,
      commentDetail: {},
    };
  },
  async created() {
    await this.getScriptList();
    this.getCommentList();
  },
  methods: {
    async getCommentList(reset) {
      // const parms = JSON.parse(JSON.stringify(this.tableFilter1))
      reset && (this.tableFilter1.currPage = 1)

      this.loading = true;
      const data = await this.$api.message
        .getCommentList(this.tableFilter1)
        .finally(() => {
          this.loading = false;
        });
      this.list = data.data;
      this.total = data.count;
    },
    openDialog(ref, detail = null) {
      if (detail && !this.permissions.scenarioComment.edit) return;
      this.commentDetail = detail;
      this.$refs[ref] && (this.$refs[ref].showDialog = true);
    },
    async getScriptList() {
      const data = await this.$api.shop.getScriptList({
        page: 1,
        limit: 100,
      });
      this.scripts = data.data;
      this.tableFilter1.scenarioId = this.scripts[0].id;
    },
    async moveComment(id, move, disable) {
      if (disable) return;
      this.loading = true;
      await this.$api.message.moveComment({ id, move }).finally(() => {
        this.loading = false;
      });
      this.$message.success(move ? "下移成功！" : "上移成功！");
      this.getCommentList();
    },
    async topComment(id, topFlag) {
      this.loading = true;
      await this.$api.message
        .topComment({ id, topFlag: !topFlag })
        .finally(() => {
          this.loading = false;
        });
      this.$message.success(topFlag ? "取消置顶成功！" : "置顶成功！");
      this.getCommentList();
    },
    async changeTransparency(id, isTransparency) {
      this.loading = true;
      await this.$api.message
        .changeTransparency({ id, isTransparency })
        .finally(() => {
          this.loading = false;
        });
      this.$message.success(isTransparency ? "修改为剧透！" : "修改为正常！");
      this.getCommentList();
    },
    deleteComment(id) {
      this.$confirm("此操作将永久删除该评论, 是否继续?", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      }).then(async () => {
        await this.$api.message.deleteComment(id);
        this.getCommentList();
      });
    },
    handleSizeChange(size) {
      this.tableFilter1.limit = size;
      this.getCommentList();
    },
    handleCurrentChange(page) {
      this.tableFilter1.currPage = page;
      this.getCommentList();
    },
  },
  watch: {
    // "tableFilter1.currPage"() {
    //   this.getCommentList();
    // },
    // "tableFilter1.scenarioId"() {
    //   this.getCommentList(true);
    // },
  },
  computed: {
    isLast() {
      return (
        (this.tableFilter1.currPage - 1) * this.tableFilter1.limit +
          this.list.length ===
        this.total
      );
    },
    ...mapState(["permissions"]),
  },
};
</script>
<style lang="scss" scoped>
@import "./common/style/shop.scss";
@import "./common/style/form.scss";
.item-date {
  text-align: left;
}

</style>